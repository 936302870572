import React, { useContext, useEffect, useState } from 'react'
import { ContainerPosts, Footer, Link, Loading, Newsletter } from '~/components'
import * as st from '~/assets/styl/CategoriePage.module.styl'
import ArrowBackIcon from '~/assets/svg/icon-yellow-left.svg'
import { useApiBlog as api } from '~/siteApi'
import { PageProps } from 'gatsby'
import cn from 'classnames'
import { context } from '~/layouts'

const CategoriePage = ({ params: { slug } }: PageProps) => {
  const [posts, setPosts] = useState([])
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState([])
  const slugFormat = slug
    ? slug
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\s+/g, '-')
        .toLowerCase()
    : ''

  const getCategoryNameById = (categoryId) => {
    const category = categories.find((cat) => cat.id === categoryId)
    return category ? category.name : 'Categoria desconhecida'
  }
  const { currentPage } = useContext(context)
  const [totalPages, setTotalPages] = useState(0)
  const postsPerPage = 12

  useEffect(() => {
    setTimeout(() => {
      const fetchPosts = async () => {
        try {
          const categoryResponse = await api.requestData(
            'get',
            `categories?slug=${slugFormat}&per_page=${postsPerPage}&page=${currentPage}`
          )
          setCategory(categoryResponse.data)
          setTotalPages(categoryResponse?.headers['x-wp-totalpages'])

          const postsResponse = await api.requestData(
            'get',
            `posts?categories=${categoryResponse.data[0].id}`
          )
          setPosts(postsResponse.data)
          const getAllCategories = await api.requestData(
            'get',
            'categories?per_page=100'
          )
          setCategories(getAllCategories.data)
        } catch (error) {
          console.error('Erro ao carregar', error)
        }
      }
      fetchPosts()
    }, 0)
  }, [slug, currentPage])

  const countCategories = categories.filter((c) => c.count > 0)

  return (
    <>
      {(category && category.length > 0 && (
        <div
          style={{ paddingLeft: '0', paddingRight: '0' }}
          className={st.core}
        >
          <div className={st.grid}>
            <div className={st.header}>
              <Link href="/blog">
                <img src={ArrowBackIcon} alt="Voltar" />
                Voltar
              </Link>
              <h1 className={st.title}>
                Categoria: <span>{category[0]?.name}</span>
              </h1>
              <div className={st.filter}>
                <div className={st.filters}>
                  <span>Filtrar por categoria</span>
                  {countCategories.map(({ name: tag, slug: url }, index) => (
                    <Link
                      key={index}
                      href={`/blog/categoria/${url}`}
                      className={cn({
                        [st.active]: slugFormat === url,
                      })}
                    >
                      {tag}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <ContainerPosts
              categoriesName={getCategoryNameById}
              searchOrFilter
              posts={posts}
              totalPages={totalPages}
            />
          </div>
          <Newsletter categorieOrSearch />
        </div>
      )) || <Loading visible />}
      <Footer />
    </>
  )
}

export default CategoriePage
